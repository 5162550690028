.beamImageDataPointBlock {
  position: relative;
  color: var(--beam-color--sky-800);
  text-align: center;
}

.icon {
  width: 50px;
  margin: 0 auto;
  display: inline-block;
}

.datum {
  display: block;
  font-family: var(--beam--font-family-base);
  font-weight: 900;
  font-size: 16px;
}

.description {
  display: block;
  font-family: var(--beam--font-family-base);
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
}

@media screen and (min-width: 800px) {
  .icon {
    width: 60px;
  }

  .datum {
    font-size: 24px;
  }

  .description {
    font-size: 12px;
  }
}
