.link {
  color: var(--beam-color--sky-500);
  text-decoration: underline;
}

.link-icon {
  text-decoration: none;
}

.link:hover {
  text-decoration: none;
  cursor: pointer;
}
