:global(sl-tooltip.beam--tooltip) {
  --sl-tooltip-arrow-size: 0;
  background-color: var(--beam-color--sky-800);
}

:global(sl-tooltip.beam--tooltip::part(body)) {
  background-color: var(--beam-color--sky-800);
  border-radius: 8px;
  min-width: 220px;
  padding: 15px;
  font-family: var(--beam--font-family-base);
  font-weight: 500;
  font-size: var(--beam--font-size-small);
  line-height: var(--beam--font-size-large);
  letter-spacing: 0.008em;
  pointer-events: all; /*the default settings disable pointer events inside the tooltip*/
}

@media only screen and (max-width: 430px) {
  :global(sl-tooltip.beam--tooltip::part(body)) {
    min-width: 250px;
    max-width: 250px;
  }
}
