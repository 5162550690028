@import './_colors.css';
@import './_fonts.css';
@import './_shoelace.css';
@import './_tailwind.css';

:root {
  /**
   * Borders and Spacing
   */
  --beam--border-radius: 8px;
  --beam--border-radius-small: 4px;
  /**
   * Typography
   */
  --beam--font-family-base: 'Mabry', sans-serif;
  --beam--font-family-paragraph: 'Apfel Grotezk', sans-serif;
  --beam--font-weight-light: 300;
  --beam--font-weight-normal: 400;
  --beam--font-weight-medium: 500;
  --beam--font-weight-semibold: 500; /* Mabry does not have a 600 weight font */
  --beam--font-weight-bold: 700;
  --beam--font-weight-black: 900;
  --beam--font-size-2x-small: 10px; /* Graph/Bar Labels */
  --beam--font-size-x-small: 12px; /* Inputs, Secondary Text */
  --beam--font-size-small: 14px; /* Body Text, Tables, Buttons */
  --beam--font-size-medium: 16px; /* H3, Form Labels */
  --beam--font-size-large: 20px; /* Sub-Headings, Small Metrics */
  --beam--font-size-x-large: 24px; /* H2 */
  --beam--font-size-2x-large: 32px; /* Medium Metrics */
  --beam--font-size-3x-large: 36px; /* H1 */
  --beam--font-size-4x-large: 48px; /* Large Metrics */
  /**
   * Tables
   */
  --beam--table-cell-x-padding: 16px;
  --beam--table-cell-y-padding: 14px;
}

/* Thanks https://www.digitalocean.com/community/tutorials/css-scrollbars */
/* Scrollbars for Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--beam-color--charcoal-100) inherit;
}

/* Scrollbars for Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: inherit;
  box-shadow: none !important; /* Overrides index.css */
}

*::-webkit-scrollbar-thumb {
  background-color: var(--beam-color--charcoal-100) !important; /* Overrides index.css */
  border-radius: 8px;
  border: 0;
}

html,
body {
  font-family: var(--beam--font-family-base) !important;
  text-rendering: geometricPrecision;
}

p {
  font-family: var(--beam--font-family-paragraph);
  font-style: normal;
  font-weight: var(--beam--font-weight-normal);
  font-size: var(--beam--font-size-large);
  line-height: 2;
  color: var(--beam-color--black);
}

h1,
.beam--heading--1 {
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: var(--beam--font-weight-black);
  font-size: var(--beam--font-size-3x-large);
  line-height: var(--beam--font-size-4x-large);
  margin: 0.67em 0;
  color: var(--beam-color--sky-800);
}

h2,
.beam--heading--2 {
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: var(--beam--font-weight-black);
  font-size: var(--beam--font-size-x-large);
  line-height: var(--beam--font-size-2x-large);
  margin: 0.83em 0;
  color: var(--beam-color--sky-800);
}

.beam--heading--thin--2 {
  font-family: var(--beam--font-family-base);
  font-weight: var(--beam--font-weight-semibold);
  font-size: var(--beam--font-size-large);
  line-height: var(--beam--font-size-2x-large);
  letter-spacing: 0.008em;
  color: var(--beam-color--sky-800);
}

h3,
.beam--heading--3 {
  font-family: var(--beam--font-family-base);
  font-size: var(--beam--font-size-medium);
  font-weight: var(--beam--font-weight-black);
  line-height: var(--beam--font-size-2x-large);
  margin: 1em 0;
  color: var(--beam-color--sky-800);
}

p,
.beam--paragraph {
  font-family: var(--beam--font-family-paragraph);
  font-style: normal;
  font-weight: var(--beam--font-weight-normal);
  font-size: var(--beam--font-size-large);
  line-height: 2;
  color: var(--beam-color--black);
}

.beam--paragraph--small {
  font-family: var(--beam--font-family-paragraph);
  font-size: var(--beam--font-size-small);
  font-weight: var(--beam--font-weight-normal);
  line-height: var(--beam--font-size-x-large);
}

a {
  text-decoration: underline;
  color: var(--beam-color--sky-500);
  cursor: pointer;
}

/**
 * Used to wrap page content to the right of the sidebar.
 * Content has a max-width and gutter on each side.
 */
.beam--page-content {
  /*@apply grid max-w-5xl grid-cols-1;*/
}

/**
 * Modifier - removes gutter from page content.
 */
.beam--page-content--no-gutter {
  margin-left: -20px;
  margin-right: -20px;
}

/**
 * Modifier - removes white space from the sides
 */
.MuiContainer-root {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
