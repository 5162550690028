.header {
  font-family: var(--beam--font-family-base);
  font-size: var(--beam--font-size-medium);
  font-weight: var(--beam--font-weight-black);
  line-height: 24px;
}

.subheader {
  font-family: var(--beam--font-family-base);
  font-size: var(--beam--font-size-medium);
  font-weight: var(--beam--font-weight-black);
  color: var(--beam-color--charcoal-800);
}

.datum {
  width: 100%;
}

.label {
  font-family: var(--beam--font-family-base);
  font-size: var(--beam--font-size-small);
  font-weight: var(--beam--font-weight-bold);
  line-height: 20px;
  color: var(--beam-color--sky-800);
}

.label span {
  border-color: var(--beam-color--charcoal-200);
}

.value {
  font-family: var(--beam--font-family-base);
  font-size: var(--beam--font-size-large);
  font-weight: var(--beam--font-weight-bold);
  line-height: 20px;
  color: var(--beam-color--sky-800);
}

.description {
  font-family: var(--beam--font-family-paragraph);
  font-size: var(--beam--font-size-medium);
  font-weight: var(--beam--font-weight-normal);
}
