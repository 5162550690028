.dataWikiPageContainer {
  @apply desktop:w-[52vw];
}
.dataWikiPageContainer h2 {
  margin: 16px 0 0;
  padding-bottom: 8px;
}

.paragraphBlock h3 {
  color: var(--beam-color--black);
  font-family: var(--beam--font-family-base);
  font-weight: var(--beam--font-weight-black);
  margin: 0 0 4px 0;
}

.paragraphBlock p {
  line-height: var(--beam--font-size-2x-large);
  font-size: var(--beam--font-size-medium);
}

.paragraphBlockGroup {
  @apply space-y-4;
}

.paragraphHighlight {
  color: var(--beam-color--sky-800);
}

.calculationBox {
  background-color: var(--beam-color--lime-50);
  padding: 24px;
  border-radius: var(--beam--border-radius);
  width: 100%;
}

.operandValue {
  padding-bottom: 10px;
  white-space: nowrap;
}
