.description {
  font-family: var(--beam--font-family-paragraph) !important; /* Overrides index.css */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.link {
  font-family: var(--beam--font-family-paragraph) !important; /* Overrides index.css */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-decoration: underline;
  color: var(--beam-color--sky-500);
}

.nonprofitPartnersTable table thead th {
  text-align: left;
}
.nonprofitImage {
  width: 83px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.boostACause {
  padding: 30px 0 10px;
}

.modalDescription {
  font-family: var(--beam--font-family-paragraph) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: var(--beam-color--charcoal-500);
  padding-bottom: 8px;
  letter-spacing: 0.4px;
}

.promoTextTitle {
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
}

.promoText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 12px;
}

.modalPromoPreview {
  line-height: 24px;
  text-align: center;
  padding: 16px 0px 8px;
}

.modalSubtext {
  font-family: var(--beam--font-family-paragraph) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  color: var(--beam-color--charcoal-500);
}

.inputLabel {
  font-family: var(--beam--font-family-base) !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: var(--beam-color--sky-800);
}

.toLabel {
  font-family: var(--beam--font-family-base) !important; /* Override index.css */
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: var(--beam-color--charcoal-500);
}

.linkToBoostACause {
  font-family: var(--beam--font-family-paragraph) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: underline;
  color: var(--beam-color--sky-500);
}

.dateRange {
  padding: 10px 0px 0px;
}

.button {
  margin-top: 10px;
}

.modalDropDown {
  padding: 24px 0px 0px;
}

.modalTextBoxContainer {
  padding: 24px 0px 0px;
  position: relative;
}

.nonprofitBoostDescription {
  font-family: var(--beam--font-family-paragraph) !important; /* Overrides index.css */
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.campaignNameCharacterCount {
  font-size: 12px;
  color: var(--beam-color--charcoal-600);
  font-weight: 600;
  padding-top: 2px;
}

.overLimit {
  border-color: var(--beam-color--cherry-600);
  color: var(--beam-color--cherry-600);
}
