.fileUploadContainer {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
}

.fileUploadButton,
.clearButton {
  position: absolute;
  right: 0;
}

.fileUploadButton {
  z-index: 0;
  margin: 0px 3px 4px 0px;
}
.clearButton {
  z-index: 2;
}

.fileUploadWidget {
  position: relative;
  width: 100%;
  height: 32px; /* Primarily for Safari so the user has a box to click on */
  line-height: 32px;
  text-align: left;
  text-decoration: underline;
  -webkit-rtl-ordering: left;
  display: inline;
  z-index: 1;
  flex-grow: 1;
  cursor: pointer;
  color: var(--beam-color--sky-800);
}

.existingFilename {
  position: relative;
  text-decoration: underline;
  line-height: 32px;
  display: inline;
  white-space: nowrap;
  z-index: 0;
  color: var(--beam-color--sky-800) !important;
}

.fileUploadWidget::file-selector-button {
  display: none;
}
