sl-details::part(base) {
  background-color: var(--beam-color--sky-50);
  border: 0;
}

sl-details::part(summary) {
  color: var(--beam-color--sky-600);
  font-size: var(--beam--font-size-medium);
  font-weight: var(--beam--font-weight-semibold);
}

sl-details::part(content) {
  color: var(--beam-color--sky-600);
  font-size: var(--beam--font-size-small);
  font-weight: var(--beam--font-weight-normal);
  padding-top: 0;
}
