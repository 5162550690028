.campaignDetailsHeader {
  font-family: var(--beam--font-family-base);
  font-size: var(--beam--font-size-medium);
  color: var(--beam-color--charcoal-800);
  font-weight: var(--beam--font-weight-bold);
}

.campaignDetailsDatum {
  font-family: var(--beam--font-family-paragraph);
  font-size: var(--beam--font-size-small);
}

.campaignParagraph {
  font-family: var(--beam--font-family-paragraph);
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
}
