.sticker {
  font-family: var(--beam--font-family-base);
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  background-color: inherit;
  padding: 6px 8px;
  border-radius: 12px;
  white-space: nowrap;
  display: inline-block;
  width: min-content;
}

.icon {
  margin-right: 3px;
}

.variantInfo {
  background-color: var(--beam-color--lime-500);
  color: var(--beam-color--coral-800);
}

.variantWarning {
  background-color: var(--beam-color--coral-100);
  color: var(--beam-color--coral-800);
}

.variantPositive {
  background-color: var(--beam-color--lime-200);
  color: var(--beam-color--moss-800);
}

.variantNegative {
  background-color: var(--beam-color--cherry-100);
  color: var(--beam-color--cherry-800);
}

.variantFailure {
  background-color: var(--beam-color--cherry-600);
  color: var(--beam-color--white);
}

.variantPending {
  background-color: var(--beam-color--sun-100);
  color: var(--beam-color--moss-800);
}
