.miniCTA {
  background-color: var(--beam-color--sun-50);
}

.icon {
  float: left;
}
.icon svg {
  margin-right: 18px;
}

.title {
  font-family: var(--beam--font-family-base) !important; /* Overrides index.css */
  font-size: 14px;
  font-weight: 700;
  color: var(--beam-color--charcoal-800);
}

.description {
  font-family: var(--beam--font-family-paragraph) !important; /* Overrides index.css */
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: var(--beam-color--charcoal-500);
  text-align: justify;
}

.right {
  margin-top: 18px;
  display: inline-block;
}

@media screen and (min-width: 800px) {
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .left,
  .right {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }
  .left {
    flex: 4;
  }

  .right {
    margin: 0 12px;
    display: flex;
    flex: 3;
    align-items: stretch;
    justify-content: center;
  }

  .button {
    display: inline-block;
  }
}
