.closeModal:hover {
  cursor: pointer;
}

.roundedTextfield::part(base) {
  border-radius: var(--beam--border-radius);
}

.emailError::part(input) {
  border: 1px solid var(--beam-color--error);
}

.emailError::part(form-control-help-text) {
  color: var(--beam-color--error);
  font-size: 11px;
  margin-top: 0px !important;
  position: absolute;
}
