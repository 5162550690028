.list {
  margin: 1em;
  padding: 1em;
  list-style: auto;
  font-family: var(--beam--font-family-paragraph);
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}

.list {
  font-size: 14px;
}

.button {
  display: inline-block !important; /* PPOR-597 I'll get back to this later; Just make this not take up the full width */
}

.button::part(base) {
  white-space: normal; /* Special exception.  Maybe make the button text shorter? */
}
