.yotpoIntegrationItemContainer {
  width: 622px;
  flex-shrink: 0;
  border: 1px solid var(--beam-color--charcoal-100);
  padding: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.yotpoItemStoreIdHeader {
  color: var(--beam-color--sky-800);
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-bottom: 10px;
}

.yotpoItemIntegrationId {
  color: var(--beam-color--sky-800);
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-top: 0;
}

.yotpoItemIntegrationDetails {
  display: flex;
  flex-direction: column;
}

.yotpoItemDisableButton {
  border-radius: 4px;
  border: 2px solid var(--decorative-charcoal-800);
  background: var(--beam-color--white);
  padding: 12px 32px;
  color: var(--beam-color--sky-800);
  font-weight: 700;
}

.yotpoItemDisableButton:hover {
  background: var(--beam-color--black);
  color: var(--beam-color--white);
}

.modalButtons {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 20px 0px;
}

.modalBodyDescription {
  text-align: center;
}
