sl-textarea.beam--textarea::part(base) {
  width: 100%;
  border: 1px solid var(--beam-color--charcoal-200);
}

sl-textarea.beam--textarea::part(textarea) {
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: var(--beam-color--grey);
}

sl-textarea.beam--textarea::part(textarea)::placeholder {
  color: var(--beam-color--charcoal-200);
}

sl-textarea.beam--textarea::part(form-control-label) {
  color: var(--beam-color--sky-800);
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 48px;
  margin-bottom: 8px;
}
