.beam--alert::part(base) {
  @apply border-none;
}

.beam--alert::part(message) {
  @apply py-2 px-3 text-xs font-black tracking-wide;
}

.beam--alert--has-icon::part(message) {
  @apply pl-1;
}

.beam--alert::part(icon) {
  padding-inline-start: 8px;
}

.beam--alert::part(close-button) {
  padding-inline-end: 0;
}

/*
 * Variants:
 * primary | neutral | success | warning | danger
 */

/* Primary */

.beam--alert--primary::part(base) {
  @apply bg-sky-100 text-sky-800;
}

.beam--alert--primary::part(icon) {
  @apply text-sky-800;
}

/* Success */

.beam--alert--success::part(base) {
  @apply bg-lime-100 text-charcoal-600;
}

.beam--alert--success::part(icon) {
  @apply text-charcoal-500;
}

/* Warning */

.beam--alert--warning::part(base) {
  @apply bg-sun-100 text-coral-600;
}

.beam--alert--warning::part(icon) {
  @apply text-coral-500;
}

/* Danger */

.beam--alert--danger::part(base) {
  @apply bg-cherry-50 text-cherry-800;
}

.beam--alert--danger::part(icon) {
  @apply text-cherry-800;
}

/* Neutral */

.beam--alert--neutral::part(base) {
  @apply bg-charcoal-100 text-charcoal-800;
}

.beam--alert--neutral::part(icon) {
  @apply text-charcoal-600;
}
