.description {
  font-family: var(--beam--font-family-paragraph) !important; /* Overrides index.css */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  margin: 16px 0;
}

.link {
  font-family: var(--beam--font-family-paragraph) !important; /* Overrides index.css */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-decoration: underline;
  color: var(--beam-color--sky-500);
}

.expandedInvoiceNumberHeader {
  font-family: var(--beam--font-family-base) !important; /* Overrides index.css */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--beam-color--charcoal);
}

.expandedLineItemHeader {
  color: var(--beam-color--charcoal-800);
  font-family: var(--beam--font-family-base) !important; /* Overrides index.css */
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-top: 10px;
}

.nonPpgfNonprofitsDisclaimer,
.nonPpgfNonprofitsDisclaimer > p {
  color: var(--beam-color--sky-800);
  font-family: var(--beam--font-family-base) !important; /* Overrides index.css */
  font-size: var(--beam--font-size-small);
  font-weight: var(--beam--font-weight-black);
  line-height: 18px;
}

.mainInvoiceTable > :global(.beam--table--thead--th:first-child) {
  width: 100%;
}

.mainInvoiceTable :global(.beam--table--thead--th) {
  text-align: left;
}

.expandedViewTable > table > tfoot {
  border-top: 1px solid var(--beam-color--charcoal-100);
}

.expandedViewTable table thead tr th:last-child,
.expandedViewTable table tbody tr td:last-child,
.expandedViewTable table tfoot tr th:last-child {
  text-align: right;
}

.setUpPPGF {
  padding: 30px 0 0;
}

.fixPpgf {
  background-color: var(--beam-color--cherry-100);
}
