.card {
  border-radius: var(--beam--border-radius);
  font-family: var(--beam--font-family-base);
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  display: block;
}

:global(.beam--card--hide-border::part(base)) {
  border: 0;
  border-radius: var(--beam--border-radius);
  height: 100%;
}

:global(sl-card.beam--card::part(base)) {
  border-radius: var(--beam--border-radius);
  background-color: inherit;
}
