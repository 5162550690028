.beam--button {
  display: inline-block;
}

/**
 * Block variant
 * Currently the default, but should actually be a modifier
 * because we don't want buttons to stretch most of the time.
 * Can also be set via Tailwind `block` / `inline-block` class.
 */
.beam--button--block {
  display: block;
}

/**
 * Primary button
 */
.beam--button::part(base) {
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 900;
  white-space: nowrap;
}

.font-normal::part(base) {
  font-weight: var(--beam--font-weight-normal);
}

.font-medium::part(base) {
  font-weight: var(--beam--font-weight-medium);
}

/**
 * Secondary ("basic") button
 */

.beam--button--basic::part(base) {
  background-color: var(--beam-color--sun);
  border-color: var(--beam-color--sun);
  color: var(--beam-color--sky-800);
  border-radius: var(--beam--border-radius);
}

/**
 * Basic Blue button
 */
.beam--button--basic_blue::part(base) {
  background-color: var(--beam-color--sky-500);
  border-color: var(--beam-color--sky-500);
  color: var(--beam-color--white);
  border-radius: var(--beam--border-radius-small);
  font-weight: 500;
}

/**
 * Neutral button (disabled state for primary/secondary button)
 */
.beam--button--neutral::part(base),
.beam--button--locked::part(base) {
  background-color: var(--beam-color--charcoal-100);
  border-color: var(--beam-color--charcoal-100);
  color: var(--beam-color--charcoal-800);
  border-radius: var(--beam--border-radius);
}

/**
 * Mini button - deprecated, use "size" prop instead
 */

.beam--button--mini::part(base) {
  background-color: var(--beam-color--sun);
  border-color: var(--beam-color--sun);
  color: var(--beam-color--charcoal-800);
  border-radius: var(--beam--border-radius);
  font-weight: 500;
}

/**
 * Elevated (buttons with heavy drop shadow, meant to be used for marketing site)
 */

.beam--button--elevated::part(base) {
  background-color: var(--beam-color--sun);
  color: var(--beam-color--charcoal-800);
  border: 2px solid var(--beam-color--charcoal-800);
  box-shadow: 4px 6px 0px var(--beam-color--sky-500);
  border-radius: var(--beam--border-radius);
}

.beam--button--elevated::part(base):active {
  box-shadow: 1px 1px 0px var(--beam-color--sky-500);
  transform: translate(3px, 5px);
}

/**
 * White button ("secondary" style for elevated theme)
 */

.beam--button--white::part(base) {
  background-color: var(--beam-color--white);
  color: var(--beam-color--charcoal-800);
  border: 2px solid var(--beam-color--charcoal-800);
  box-shadow: 4px 6px 0px var(--beam-color--sky-500);
  border-radius: var(--beam--border-radius-small);
}

.beam--button--white::part(base):active {
  box-shadow: 1px 1px 0px var(--beam-color--sky-500);
  transform: translate(3px, 5px);
}

/**
 * Flat White button
 */

.beam--button--flat_white::part(base) {
  background-color: var(--beam-color--white);
  color: var(--beam-color--charcoal-800);
  border: 2px solid var(--beam-color--charcoal-800);
  border-radius: var(--beam--border-radius-small);
}

/**
 * CTA Mini (should probably be moved to CTA component)
 */

.beam--mini-cta--mini {
  margin-left: 60px;
}

/**
 * Text variant - a plain button with no border
 */

.beam--button--text::part(base) {
  padding: 0;
  text-decoration: underline;
  color: inherit;
}

/**
 * "Input" variant resembles a form input element such as a text box or select menu.
 * Can be used as the toggle control for a custom dropdown.
 */

.beam--button--input {
  display: inline-block;
}

.beam--button--input::part(base) {
  --sl-button-font-size-small: var(--sl-font-size-2x-small);
  --sl-button-font-size-medium: var(--sl-font-size-x-small);
  --sl-button-font-size-large: var(--sl-font-size-small);
  background: var(--beam-color--white);
  border-color: var(--beam-color--charcoal-100);
  color: var(--beam-color--charcoal-600);
  font-family: var(--beam--font-family-base);
}

.beam--button--input:hover:not(.button--disabled)::part(base) {
  color: var(--beam-color--charcoal-600);
}

.beam--button--input:active:not(.button--disabled)::part(base) {
  background-color: var(--beam-color--charcoal-50);
  border-color: var(--beam-color--charcoal-200);
  color: var(--beam-color--charcoal-600);
}

/**
 * Emphasis White - Similar to the "white" variant, but with bolder accents (font, border)
 */
.beam--button--emphasis_white::part(base) {
  font-size: var(--sl-font-size-small);
  background: var(--beam-color--white);
  border-color: var(--beam-color--charcoal-800);
  border-width: 2px;
  border-radius: var(--beam--border-radius-small);
  color: var(--beam-color--sky-800);
  font-family: var(--beam--font-family-base), sans-serif;
  padding: 0;
  margin: 0;
}

/**
 * Borderless White
 */
.beam--button--borderless_white::part(base) {
  font-size: var(--sl-font-size-small);
  background: var(--beam-color--white);
  border-width: 0;
  border-radius: var(--beam--border-radius-small);
  color: var(--beam-color--sky-800);
  font-family: var(--beam--font-family-base), sans-serif;
  padding: 0;
  margin: 0;
}
