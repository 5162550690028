.cta {
  background-color: var(--beam-color--sun-100);
  margin: 5px 0;
}

sl-card.cta::part(body) {
  padding: 0.8em 1em;
}

.icon svg {
  margin-right: 18px;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.left,
.right,
.icon,
.close {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.icon {
  flex: 0 0 40px;
  vertical-align: middle;
  justify-content: center;
}

.left {
  flex: 1 1 auto;
  justify-content: center;
}

.right {
  flex: 0 0 100px;

  justify-content: center;
}

.button {
  display: inline-block;
}

.close {
  flex: 0 0 auto;
  vertical-align: middle;
  justify-content: center;
  margin-left: 20px;
  margin-right: 10px;
}
