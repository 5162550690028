.promoPillbox {
  border-radius: 18.37px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 800;
  height: 24px;
  padding: 4px 12px;
  max-width: 100%;
}

.widgetTitle {
  font-size: 14px;
  font-weight: 800;
  line-height: 24px;
  margin-right: 8px;
}

.widgetDescription {
  font-size: 14px;
  line-height: 19.12px;
}

.nonprofitButtonContainer {
  width: 100%;
  gap: 10px;
  padding-top: 8px;
}

.nonprofitButtonContainer > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 32px;
  padding: 6px 4px;
  position: relative;
}

.nonprofitNotification {
  position: absolute;
  right: 16px;
  top: 4px;
  left: 50%;
}

.impactPreview {
  margin-top: 8px;
  padding: 12px;
  border-radius: 2px;
  border: 1px solid #00000033;
  font-family: var(--beam--font-family-paragraph);
}

.impactPreviewHeader {
  color: #606060;
}

.impactPreviewHeader > span {
  font-size: var(--beam--font-size-2x-small);
}

.impactPreviewBody {
  margin-top: 8px;
  font-size: var(--beam--font-size-small);
}

.impactProgressBar {
  margin-top: 8px;
}
