/* Dropdown form element */
.edit-delete-dropdown {
  overflow: hidden;
}

.edit-delete-dropdown-button::part(base) {
  border: none;
  background: none;
  display: block;
}
.edit-delete-menu-item {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;

  background: none;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  line-height: 12px;
  letter-spacing: 0.06em;
}

.edit-delete-menu-item span {
  /* Icon */
  margin-right: 4px;
  display: inline-block;
  margin-bottom: -2px;
}

.edit-delete-menu-item::part(checked-icon) {
  width: 5px;
}

.edit-delete-menu-item::part(base) {
  background: none;
}

.edit-delete-menu-item::part(base):hover,
.edit-delete-menu-item::part(base):active,
.edit-delete-menu-item::part(base):focus {
  background: none;
  text-decoration: underline;
}

.delete-menu-item div {
  color: var(--beam-color--cherry-500);
}
