.beam-navigation {
  position: relative;
  margin-top: 22px;
  min-height: 700px;
  height: 90vh;
  display: flex;
  flex-direction: column;
}

/* ====== Beam Logo ====== */

.beam-navigation--beam-logo-container {
  margin-top: 24px;
  display: flex;
  align-items: center;
  height: 50px;
  margin-left: 10px;
}
.beam-navigation--beam-logo {
  width: 62px;
  height: 32.94px;
  display: flex;
  align-items: center;

  > img {
    /* override for max-width default from tailwind */
    max-width: 62px;
  }
}

/* ====== Nav Blocks ====== */

.nav-block {
  padding-left: 16px;

  &:not(:first-of-type) {
    margin-top: 24px;
  }
  /* Hides nav blocks when they only have one item, usually the header.*/
  &:has(> :only-child) {
    display: none;
  }

  > .navLink:not(:first-of-type),
  > .nav-tree-wrapper {
    margin-top: 20px;
  }
}

/* ====== Section Headers (Nav block titles) ====== */

.beam-navigation--section-header {
  margin-bottom: 14px;
  text-decoration: none;
  font-size: 0.75em;
  font-weight: 400;
  color: var(--beam-color--charcoal-500);
}

/* ====== Nav Links ====== */

.navLink {
  font-weight: 900;
  color: var(--beam-color--charcoal-500);
  width: auto;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: var(--beam--border-radius);

  /* Default link icon color */
  > svg {
    color: var(--beam-color--blue-gray);
  }
}

/* Active nav links */
.navLink.active {
  color: var(--beam-color--sky-500);
  font-weight: 900;

  > svg {
    color: var(--beam-color--sky-500);
  }
  > .beam-navigation--link-label {
    font-weight: 900;
    color: var(--beam-color--sky-500);
  }
}
/* Hover styles for links and icons */
.navLink:hover {
  color: var(--beam-color--sky-500);
  text-decoration: none;

  > svg {
    color: var(--beam-color--sky-500);
  }
}
/* Icons that are placed at the end of nav links */
.navLink-suffix-icon {
  margin-left: 4px;
}

/* ====== Partner Logo ====== */

.beam-navigation--partner-logo {
  display: flex;
  align-items: center;
  margin-left: 12px;
  padding-left: 12px;
  max-width: 120px;
  max-height: 50px;
  min-height: 32.94px;
  border-left: 1px solid var(--beam-color--charcoal-100);

  > img {
    max-width: 100%;
    max-height: 50px;
    width: auto;
    height: auto;
  }
}

/* ====== User badge ====== */

.beam-navigation--user-badge {
  position: absolute;
  bottom: 50px;
}

.user-badge-link {
  margin-left: 0;

  &:not(:first-of-type) {
    margin-top: 10px;
    padding-top: 0;
  }
}

/* ====== Link Labels ====== */

.beam-navigation--link-label {
  margin-left: 15px;
  font-family: var(--beam--font-family-base) !important; /* Overrides index.css */
  font-size: 0.875em;
  font-weight: 500;
  text-decoration: none;
}

/* Multi-site dropdown */

sl-select.beam-navigation--site-drop-down::part(combobox) {
  background-color: var(--beam-color--sky-500);
  margin-bottom: 20px;
}

sl-select.beam-navigation--site-drop-down::part(display-input) {
  color: var(--beam-color--charcoal-50);
  font-weight: lighter !important;
}

/* ====== Tree ====== */

.beam-navigation--tree--header {
  &::part(base),
  &::part(expand-button) {
    color: var(--beam-color--charcoal-500);
  }

  /* Moves the expand button on the right and adjusts its margin */
  &::part(expand-button) {
    order: 2;
    padding: 0;
    margin-left: 8px;
  }
}
/* Tree header label base styles */
.beam-navigation--tree--header-label {
  color: inherit;
}
/* Removes default selection styles that come with the shoelace component */
.beam-navigation--tree--header::part(item--selected),
.nav-tree-child-link::part(item--selected) {
  background-color: inherit;
  border-inline-start-color: transparent;
  text-decoration: none;
}
/* Removes SL default icon margin on the tree header */
.beam-navigation--tree--header-icon {
  margin-right: 0;
}
/* Prevents the default SL tree item container from having the pointer cursor. The default container is much wider than the actual clickable link */
.nav-tree-child-link::part(base) {
  cursor: default;
}

/* Active nav tree styles*/
.beam-navigation--tree--header.active {
  &::part(base),
  &::part(item),
  > .beam-navigation--tree--header-label,
  > .beam-navigation--tree--header-icon::part(svg) {
    color: var(--beam-color--sky-500);
    font-weight: 900;
  }
}

/* Sets default tree header icon color */
.beam-navigation--tree--header-icon::part(svg) {
  color: var(--beam-color--blue-gray);
  stroke: var(--beam-color--blue-gray);
}
/* Child links within a tree */
.nav-tree-child-link {
  &:first-of-type {
    /*Sets the top margin for only the first child inside a tree. */
    margin-top: 12px;
  }
  &:not(:first-of-type) {
    /*Sets the top margin for all children inside a tree except the first. */
    margin-top: 10px;
  }
  &::part(expand-button) {
    /* Hides the default SL icon empty space to prevent misalignment on child menu items. */
    display: none;
  }
  &::part(indentation) {
    /* Sets the indentation width of the child items of the tree. This width should always match the width of the icon in parent, if any. */
    width: 24px;
  }

  > .navLink.active > .beam-navigation--link-label {
    /* Prevents the child tree items from getting bold styles when they're active */
    font-weight: 500;
  }
}
