.container {
  padding: 0 5px 20px;
}
.modalCallout {
  font-family: var(--beam--font-family-base) !important;
  font-weight: var(--beam--font-weight-black);
  line-height: 30px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: capitalize;
  color: var(--beam-color--charcoal-800) !important;
  margin: 0;
  padding-bottom: 5px;
}
.modalTitle {
  font-family: var(--beam--font-family-base) !important;
  font-size: var(--beam--font-size-x-large);
  font-weight: var(--beam--font-weight-black);
  line-height: 30px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: capitalize;
  color: var(--beam-color--charcoal-800) !important;
  margin: 0;
  padding-bottom: 12px;
}

.modalDescription {
  font-family: var(--beam--font-family-paragraph) !important;
  font-size: var(--beam--font-size-small);
  font-weight: var(--beam--font-weight-normal);
  line-height: 20px;
  text-align: center;
  color: var(--beam-color--charcoal-800);
  padding-bottom: 8px;
  letter-spacing: 0.4px;
}

.subheading {
  font-family: var(--beam--font-family-base) !important;
  font-size: var(--beam--font-size-medium);
  font-weight: var(--beam--font-weight-black);
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: var(--beam-color--charcoal-800);
}

.twoColumnContainer {
  display: flex;
}

.leftColumn {
  width: 50%; /* FIX FLEX */
  margin-bottom: 10px;
}

.rightColumn {
  width: 50%; /* FIX FLEX */

  font-family: var(--beam--font-family-paragraph);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: var(--beam-color--charcoal-800) !important;
}

.list {
  list-style-type: decimal;
  margin-left: 22px;
}

.listItem {
  padding: 10px 0px;
}

.button {
  margin-bottom: 10px;
}
