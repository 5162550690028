.block {
  border-radius: var(--beam--border-radius);
}

.metricsBlocksGrid :global(sl-card.beam--card) {
  height: 100%;
}

:global(sl-tooltip.subscriberSignupLiftTooltip::part(body)) {
  max-width: 360px !important;
}

.roiMetricComparisonSubheading {
  color: var(--beam-color--moss-800);
  font-weight: 900;
  font-size: var(--beam--font-size-x-small);
}

.roiMetricSubheading__plain {
  color: var(--beam-color--sky-800);
  font-weight: 900;
  font-size: var(--beam--font-size-x-small);
}

.roiMetricSubheading__plain__button::part(base) {
  color: var(--beam-color--sky-800);
  font-weight: 500;
  font-size: var(--beam--font-size-x-small);
  line-height: normal;
  min-height: auto;
}

.roiMetricSubheading__plain__button::part(label) {
  padding: 0;
}

.slot {
  border-radius: var(--beam--border-radius);
  border: 1px solid var(--beam-color--charcoal-100);
  padding: 0 10px 20px;
}

.customerEngagementCard::part(base) {
  height: 100%;
}

.tagLine {
  font-family: var(--beam--font-family-paragraph);
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
}

.businessImpactSection {
  padding: 0 0 20px;
  border-radius: var(--beam--border-radius);
}

.businessImpactDateRange {
  font-family: var(--beam--font-family-base) !important; /* Override index.css */
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--beam-color--sky-800);
}

.businessImpactPart1 {
  background-color: var(--beam-color--lime-100);
  border-radius: var(--beam--border-radius);
  margin-bottom: 20px;
}

:global(.iconOverrideOverviewPage svg) {
  margin-right: 4px;
  display: inline; /* Override index.css */
}

.impactFromActiveNonprofitsHeading,
.activeNonprofitPartnersHeader {
  display: inline-block;
}

.managePartnersLink {
  color: var(--beam-color--sky-500);
  font-family: var(--beam--font-family-base);
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.expansionButton {
  font-family: var(--beam--font-family-base) !important; /* Override index.css */
  font-size: 14px;
  font-weight: 700;
  color: var(--beam-color--sky-800);
}

.dateRangeIndicator {
  font-family: var(--beam--font-family-paragraph) !important; /* Override index.css */
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
}

.expandedStats,
.expandedStats div {
  font-family: var(--beam--font-family-base) !important; /* Override index.css */
  margin-bottom: 5px;
}

:global(sl-tooltip.beam--tooltip::part(body)) {
  max-width: 220px;
}

.hrGivingTuesday {
  border: 1px solid var(--beam-color--charcoal-100);
}

.siteIndicatorForRoiModule {
  font-family: var(--beam--font-family-base) !important;
  font-size: var(--beam--font-size-small);
  font-weight: var(--beam--font-weight-bold);
  color: var(--beam-color--sky-800);
}
