.beamDataPointBlock {
  position: relative;
  color: var(--beam-color--sky-800);
}

.beamDataPointBlock a {
  color: var(--beam-color--sky-500);
}

.title {
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  display: block;
}

.datum {
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 900;
  display: block;
}

.datum--small {
  font-size: 32px;
}
.datum--large {
  font-size: 48px;
}

.description {
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.description--no-data {
  color: var(--beam-color--charcoal);
}

.icon {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 20px;
}

.footer {
  text-align: right;
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: block;
}
